// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-static-careers-js": () => import("./../../../src/templates/static/careers.js" /* webpackChunkName: "component---src-templates-static-careers-js" */),
  "component---src-templates-static-device-protection-js": () => import("./../../../src/templates/static/device-protection.js" /* webpackChunkName: "component---src-templates-static-device-protection-js" */),
  "component---src-templates-static-index-js": () => import("./../../../src/templates/static/index.js" /* webpackChunkName: "component---src-templates-static-index-js" */),
  "component---src-templates-static-locations-js": () => import("./../../../src/templates/static/locations.js" /* webpackChunkName: "component---src-templates-static-locations-js" */),
  "component---src-templates-static-news-js": () => import("./../../../src/templates/static/news.js" /* webpackChunkName: "component---src-templates-static-news-js" */),
  "component---src-templates-static-not-found-js": () => import("./../../../src/templates/static/not-found.js" /* webpackChunkName: "component---src-templates-static-not-found-js" */),
  "component---src-templates-static-partners-js": () => import("./../../../src/templates/static/partners.js" /* webpackChunkName: "component---src-templates-static-partners-js" */),
  "component---src-templates-static-who-we-are-js": () => import("./../../../src/templates/static/who-we-are.js" /* webpackChunkName: "component---src-templates-static-who-we-are-js" */)
}

