import scrollToElement from 'scroll-to-element'

export const isBrowser = typeof window !== 'undefined'

const DESKTOP_HEADER_OFFSET = -80
const DESKTOP_SUBHEADER_OFFSET = -80
const MOBILE_HEADER_OFFSET = -72

export function scroller(target) {
  scrollToElement(target, {
    offset: isBrowser && window.innerWidth < 1024 ? MOBILE_HEADER_OFFSET : DESKTOP_HEADER_OFFSET,
  })
}

export function handleLinkClick(to, e) {
  if (isBrowser && to.includes('#')) {
    const [anchorPath, anchor] = to.split('#')
    if (window.location.pathname === anchorPath) {
      e.preventDefault()
      scroller(`#${anchor}`)
    }
  }
}

export function handleStrippedLinkClick(to, e) {
  const [anchorPath, anchor] = to.split('#')
  const isSamePage = isBrowser && window.location.pathname === anchorPath
  const isDifferentPage = isBrowser && window.location.pathname !== anchorPath

  if (isSamePage) {
    e.preventDefault()
    return scroller(`#${anchor}`)
  }

  if (isDifferentPage) {
    window.gatsby_scroll_hash = `#${anchor}`
  }

  return true
}

export function stripHashedLocation(to) {
  return to.split('#')[0]
}

export function checkHash(location) {
  const { hash } = location
  const selector = hash ? hash.substr(1) : null
  const validElement = selector ? document.getElementById(selector) : null
  if (hash && Boolean(validElement)) scroller(hash)
}
