import 'tailwindcss/dist/base.min.css'
import { checkHash, isBrowser, scroller } from './src/utils/scroll'

const getCookieValue = (a) => {
  const b = document.cookie.match(`(^|;)\\s*${a}\\s*=\\s*([^;]+)`)
  return b ? b.pop() : ''
}

export const onClientEntry = () => {
  const isConsented = getCookieValue('cookie-consent')
  window[`ga-disable-${process.env.GATSBY_GA_TRACKING_ID}`] = !isConsented

  if (!(`IntersectionObserver` in window)) {
    require(`intersection-observer`) // eslint-disable-line global-require
  }
}

export { wrapPageElement } from './gatsby-ssr'

export const onRouteUpdate = ({ location }) => {
  let windowHash

  if (isBrowser) {
    windowHash = window.gatsby_scroll_hash
  }

  if (windowHash) {
    scroller(windowHash)
  } else {
    checkHash(location)
  }

  if (isBrowser && windowHash) {
    window.gatsby_scroll_hash = undefined
  }
}
